import styled from 'styled-components'
import breakpoints from '../../../styles/breakpoints'

import imageBgXl from '../../../images/um-time-de-epecialistas-desktop.png'
import imageBgLg from '../../../images/um-time-de-epecialistas-tablet-landscape.png'
import imageBgMd from '../../../images/um-time-de-epecialistas-tablet-portatre.png'

export const BgSection = styled.section`
  min-height: 452px;

  @media (min-width: ${breakpoints.md}) {
    background-image: url(${imageBgMd});
    background-repeat: no-repeat;
    background-position: 100% 0;
    min-height: 533px;
  }
  @media (min-width: ${breakpoints.lg}) {
    background-image: url(${imageBgLg});
    min-height: 642px;
  }
  @media (min-width: ${breakpoints.xl}) {
    background-image: url(${imageBgXl});
    min-height: 644px;
  }
`
export const Person = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #172740;
`
