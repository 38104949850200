import React from 'react'

import Icon from '../../../components/Icon'

import { BgSection, Person } from './style'

const Especialistas = () => {
  return (
    <BgSection className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <h3 className='f-sora fs-20 lh-25 fs-md-24 lh-md-30 fs-lg-32 lh-lh-40 text-blue-900 fw-400'>
              Um time de especialistas com você
            </h3>
            <p className='fs-14 lh-16 fs-md-16 lh-md-20 fs-lg-18 lh-lg-22 text-gray-650 fw-400 mb-5'>
              Temos um time formado por gestores experientes e uma equipe técnica de especialistas em diferentes estratégias. 
              Sempre gerenciando riscos, mas com olhar no futuro, tendências e inovação, utilizamos as ferramentas tecnológicas mais 
              modernas para fazer o monitoramento do mercado, fundos e análises de relatórios. 
            </p>
            <span className='fs-20 lh-24 text-gray-650 fw-600'>Comitê Executivo</span>
            <div className='d-flex align-items-center mt-3'>
              <Person>
                <Icon width={14} height={14} icon='misc/user' directory='v2' color='white' />
              </Person>
              <span className='fs-16 lh-20 fw-600 ml-2 text-gray-650'>Daniel Castro Domingos da Silva</span>
            </div>
            <div className='d-flex align-items-center mt-3'>
              <Person>
                <Icon width={14} height={14} icon='misc/user' directory='v2' color='white' />
              </Person>
              <span className='fs-16 lh-20 fw-600 ml-2 text-gray-650'>Marcelo Miranda de Mattos</span>
            </div>
          </div>
        </div>
      </div>
    </BgSection>
  )
}

export default Especialistas
